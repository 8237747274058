import { getRangeValue } from "@tsparticles/engine";
import { drawCog, drawCogHole } from "./Utils.js";
const defaultHoleRadius = 44, defaultInnerRadius = 72, defaultInnerTaper = 35, defaultNotches = 7, defaultOuterTaper = 50;
export class CogDrawer {
    constructor() {
        this.validTypes = ["cog"];
    }
    afterDraw(data) {
        drawCogHole(data);
    }
    draw(data) {
        drawCog(data);
    }
    particleInit(container, particle) {
        const shapeData = particle.shapeData;
        particle.cogHoleRadius = getRangeValue(shapeData?.holeRadius ?? defaultHoleRadius);
        particle.cogInnerRadius = getRangeValue(shapeData?.innerRadius ?? defaultInnerRadius);
        particle.cogInnerTaper = getRangeValue(shapeData?.innerTaper ?? defaultInnerTaper);
        particle.cogNotches = getRangeValue(shapeData?.notches ?? defaultNotches);
        particle.cogOuterTaper = getRangeValue(shapeData?.outerTaper ?? defaultOuterTaper);
    }
}
