import { mouseDownEvent, touchStartEvent, } from "@tsparticles/engine";
import { Sounds } from "./Options/Classes/Sounds.js";
import { SoundsInstance } from "./SoundsInstance.js";
import { unmuteWindow } from "./utils.js";
const generalFirstClickHandler = () => {
    removeEventListener(mouseDownEvent, generalFirstClickHandler);
    removeEventListener(touchStartEvent, generalFirstClickHandler);
    unmuteWindow();
};
export class SoundsPlugin {
    constructor(engine) {
        this.id = "sounds";
        this._engine = engine;
        const listenerOptions = {
            capture: true,
            once: true,
        };
        addEventListener(mouseDownEvent, generalFirstClickHandler, listenerOptions);
        addEventListener(touchStartEvent, generalFirstClickHandler, listenerOptions);
    }
    getPlugin(container) {
        return Promise.resolve(new SoundsInstance(container, this._engine));
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        let soundsOptions = options.sounds;
        if (soundsOptions?.load === undefined) {
            options.sounds = soundsOptions = new Sounds();
        }
        soundsOptions.load(source?.sounds);
    }
    needsPlugin(options) {
        return options?.sounds?.enable ?? false;
    }
}
