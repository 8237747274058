export var SoundsEventType;
(function (SoundsEventType) {
    SoundsEventType["mute"] = "soundsMuted";
    SoundsEventType["unmute"] = "soundsUnmuted";
})(SoundsEventType || (SoundsEventType = {}));
export var ImageDisplay;
(function (ImageDisplay) {
    ImageDisplay["Block"] = "block";
    ImageDisplay["None"] = "none";
})(ImageDisplay || (ImageDisplay = {}));
