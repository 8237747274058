import { ExternalInteractorBase } from "@tsparticles/engine";
const popMode = "pop";
export class Popper extends ExternalInteractorBase {
    constructor(container) {
        super(container);
        this.handleClickMode = (mode) => {
            const container = this.container;
            if (mode !== popMode) {
                return;
            }
            const clickPos = container.interactivity.mouse.clickPosition;
            if (!clickPos) {
                return;
            }
            const poppedParticles = container.particles.quadTree.queryCircle(clickPos, container.retina.pixelRatio);
            if (!poppedParticles.length) {
                return;
            }
            for (const particle of poppedParticles) {
                container.particles.remove(particle);
            }
        };
    }
    clear() {
    }
    init() {
    }
    interact() {
    }
    isEnabled() {
        return true;
    }
    reset() {
    }
}
