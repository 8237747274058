import { ExportVideoInstance } from "./ExportVideoInstance.js";
export class ExportVideoPlugin {
    constructor(engine) {
        this.id = "export-video";
        this._engine = engine;
    }
    getPlugin(container) {
        return Promise.resolve(new ExportVideoInstance(container, this._engine));
    }
    loadOptions() {
    }
    needsPlugin() {
        return true;
    }
}
