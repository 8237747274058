export class ImageMask {
    constructor() {
        this.src = "";
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.src !== undefined) {
            this.src = data.src;
        }
    }
}
