export class EmittersPolygonShapeOptions {
    constructor() {
        this.angle = 0;
        this.sides = 5;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.angle !== undefined) {
            this.angle = data.angle;
        }
        if (data.sides !== undefined) {
            this.sides = data.sides;
        }
    }
}
