import { isObject } from "@tsparticles/engine";
export class SoundsVolume {
    constructor() {
        this.value = 100;
        this.max = 100;
        this.min = 0;
        this.step = 10;
    }
    load(data) {
        if (data === undefined) {
            return;
        }
        if (isObject(data)) {
            if (data.max !== undefined) {
                this.max = data.max;
            }
            if (data.min !== undefined) {
                this.min = data.min;
            }
            if (data.step !== undefined) {
                this.step = data.step;
            }
            if (data.value !== undefined) {
                this.value = data.value;
            }
        }
        else {
            this.value = data;
        }
    }
}
