import { getRangeValue } from "@tsparticles/engine";
import { drawRoundedRect } from "./Utils.js";
const fixFactorSquare = 2, fixFactor = Math.sqrt(fixFactorSquare), double = 2, defaultRadius = 5;
export class RoundedRectDrawer {
    constructor() {
        this.validTypes = ["rounded-rect"];
    }
    draw(data) {
        const { context, particle, radius } = data, fixedRadius = radius / fixFactor, fixedDiameter = fixedRadius * double, borderRadius = particle.borderRadius ?? defaultRadius;
        if ("roundRect" in context) {
            context.roundRect(-fixedRadius, -fixedRadius, fixedDiameter, fixedDiameter, borderRadius);
        }
        else {
            drawRoundedRect(context, fixedRadius, fixedDiameter, borderRadius);
        }
    }
    particleInit(container, particle) {
        const shapeData = particle.shapeData;
        particle.borderRadius = getRangeValue(shapeData?.radius ?? defaultRadius) * container.retina.pixelRatio;
    }
}
