import { Vector, deepExtend, getRandom, } from "@tsparticles/engine";
import { SimplexNoise } from "@tsparticles/simplex-noise";
const defaultOptions = {
    speed: 0.2,
    step: 250,
}, double = 2;
export class CurlNoiseGenerator {
    constructor() {
        const simplex = new SimplexNoise();
        this._simplex = simplex.noise2d;
        this.options = deepExtend({}, defaultOptions);
    }
    generate(particle) {
        const pos = particle.getPosition(), { speed, step } = this.options, x = pos.x / step, y = pos.y / step, eps = 0.001, n1a = this._simplex.noise(x, y + eps), n2a = this._simplex.noise(x, y - eps), a = (n1a - n2a) / (double * eps), n1b = this._simplex.noise(x + eps, y), n2b = this._simplex.noise(x - eps, y), b = (n1b - n2b) / (double * eps);
        particle.velocity.x = 0;
        particle.velocity.y = 0;
        return Vector.create(speed * a, speed * -b);
    }
    init(container) {
        const sourceOptions = container.actualOptions.particles.move.path.options;
        this.options.seed = sourceOptions?.seed;
        this.options.speed =
            (sourceOptions?.speed ?? defaultOptions.speed) * container.retina.pixelRatio;
        this.options.step = sourceOptions?.step ?? defaultOptions.step;
        this._simplex.seed(this.options.seed ?? getRandom());
    }
    reset() {
    }
    update() {
    }
}
