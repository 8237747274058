import { DestroyType, updateAnimation, updateColor } from "@tsparticles/engine";
export function updateGradient(particle, delta) {
    const { gradient } = particle;
    if (!gradient) {
        return;
    }
    updateAnimation(particle, gradient.angle, false, DestroyType.none, delta);
    for (const color of gradient.colors) {
        updateColor(color.value, delta);
        if (color.opacity) {
            updateAnimation(particle, color.opacity, true, DestroyType.none, delta);
        }
    }
}
