import { Vector, getRandom, isFunction, isString } from "@tsparticles/engine";
import { CurvesPathGen } from "./Curves.js";
const double = 2, doublePI = Math.PI * double;
function randomVelocity() {
    const offset = 0.8, factor = 0.6;
    return getRandom() * factor + offset;
}
export class CurvesPathGenerator {
    constructor() {
        this.options = {
            rndFunc: null,
            period: 100,
            nbHarmonics: 2,
            attenHarmonics: 0.8,
            lowValue: -0.03,
            highValue: 0.03,
        };
    }
    generate(p) {
        if (!p.pathGen) {
            const options = this.options;
            p.pathGen = CurvesPathGen(options.rndFunc, options.period, options.nbHarmonics, options.attenHarmonics, options.lowValue, options.highValue);
        }
        if (!p.curveVelocity) {
            p.curveVelocity = Vector.origin;
            p.curveVelocity.length = randomVelocity();
            p.curveVelocity.angle = getRandom() * doublePI;
        }
        else {
            p.curveVelocity.length += 0.01;
            p.curveVelocity.angle = (p.curveVelocity.angle + p.pathGen()) % doublePI;
        }
        p.velocity.x = 0;
        p.velocity.y = 0;
        return p.curveVelocity;
    }
    init(container) {
        const sourceOptions = container.actualOptions.particles.move.path.options, { options } = this;
        if (isFunction(sourceOptions.rndFunc)) {
            options.rndFunc = sourceOptions.rndFunc;
        }
        else if (isString(sourceOptions.rndFunc)) {
            options.rndFunc =
                window[sourceOptions.rndFunc] ?? this.options.rndFunc;
        }
        options.period = sourceOptions.period ?? options.period;
        options.nbHarmonics = sourceOptions.nbHarmonics ?? options.nbHarmonics;
        options.attenHarmonics = sourceOptions.attenHarmonics ?? options.attenHarmonics;
        options.lowValue = sourceOptions.lowValue ?? options.lowValue;
        options.highValue = sourceOptions.highValue ?? options.highValue;
    }
    reset(particle) {
        delete particle.pathGen;
        delete particle.curveVelocity;
    }
    update() {
    }
}
