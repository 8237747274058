import { TextFontOptions } from "./TextFontOptions.js";
import { TextLinesOptions } from "./TextLinesOptions.js";
export class TextOptions {
    constructor() {
        this.color = "#000000";
        this.font = new TextFontOptions();
        this.lines = new TextLinesOptions();
        this.text = "";
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.color !== undefined) {
            this.color = data.color;
        }
        this.font.load(data.font);
        this.lines.load(data.lines);
        if (data.text !== undefined) {
            this.text = data.text;
        }
    }
}
