import { FontTextMask } from "./FontTextMask.js";
import { TextMaskLine } from "./TextMaskLine.js";
export class TextMask {
    constructor() {
        this.color = "#000000";
        this.font = new FontTextMask();
        this.lines = new TextMaskLine();
        this.text = "";
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.color !== undefined) {
            this.color = data.color;
        }
        this.font.load(data.font);
        this.lines.load(data.lines);
        if (data.text !== undefined) {
            this.text = data.text;
        }
    }
}
