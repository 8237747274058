import { AnimatableColor, isNumber } from "@tsparticles/engine";
import { GradientColorOpacity } from "./GradientColorOpacity.js";
export class AnimatableGradientColor {
    constructor() {
        this.stop = 0;
        this.value = new AnimatableColor();
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.stop !== undefined) {
            this.stop = data.stop;
        }
        this.value = AnimatableColor.create(this.value, data.value);
        if (data.opacity !== undefined) {
            this.opacity = new GradientColorOpacity();
            if (isNumber(data.opacity)) {
                this.opacity.value = data.opacity;
            }
            else {
                this.opacity.load(data.opacity);
            }
        }
    }
}
