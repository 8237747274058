import { isFunction, isString } from "@tsparticles/engine";
const minAlpha = 0;
export class CanvasMaskPixels {
    constructor() {
        this.filter = (pixel) => pixel.a > minAlpha;
        this.offset = 4;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.filter !== undefined) {
            if (isString(data.filter)) {
                if (Object.hasOwn(window, data.filter)) {
                    const filter = window[data.filter];
                    if (isFunction(filter)) {
                        this.filter = filter;
                    }
                }
            }
            else {
                this.filter = data.filter;
            }
        }
        if (data.offset !== undefined) {
            this.offset = data.offset;
        }
    }
}
