import { Poisson } from "./Options/Classes/Poisson.js";
import { PoissonInstance } from "./PoissonInstance.js";
export class PoissonDiscPlugin {
    constructor(engine) {
        this.id = "poisson";
        this._engine = engine;
    }
    getPlugin(container) {
        return Promise.resolve(new PoissonInstance(container, this._engine));
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        let poissonOptions = options.poisson;
        if (poissonOptions?.load === undefined) {
            options.poisson = poissonOptions = new Poisson();
        }
        poissonOptions.load(source?.poisson);
    }
    needsPlugin(options) {
        return options?.poisson?.enable ?? false;
    }
}
