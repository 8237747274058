import { loadAll } from "@tsparticles/all";
import { tsParticles } from "@tsparticles/engine";
import SmokeMachine from "@bijection/smoke";

//min y maximo incluidos
const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
const randomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

const json = require("./assets/json/particles_5.json");

async function loadParticles(options) {
  await loadAll(tsParticles);
  await tsParticles.load({ id: "tsparticles", options });
}

loadParticles(json);

const canvas = document.getElementById("canvas");
const ctx = canvas.getContext("2d");
//OKs 0, 210, 255
const party = SmokeMachine(ctx, [0, 210, 255]);
party.start(); // start animating

//Primer smoke
//party.addSmoke(Math.floor(innerWidth / 2), innerHeight, 30);

/*
const gosmoke = () => {
  console.log("gosmoke");
  party.stop(); // stop animating
  party.addSmoke(randomInteger(1, innerWidth), innerHeight, 50);
  //for (var i = 0; i < 10; i++) {
    //party.step(10); // pretend 10 ms pass and rerender
  //}
  setTimeout(function () {
    party.start();
  }, 1000);

  setTimeout(gosmoke, 5000);
};
gosmoke();
*/

/*
setTimeout(function () {
  party.stop(); // stop animating

  party.addSmoke(innerWidth/2, innerHeight, 1000);
  party.addSmoke(innerWidth/2, innerHeight, 200);

  for (var i = 0; i < 10; i++) {
    party.step(10); // pretend 10 ms pass and rerender
  }

  setTimeout(function () {
    party.start();
  }, 1000);
}, 1000);
*/

//RANDOM
/*
party.setPreDrawCallback(function () {
  //party.stop(); // stop animating

  //Mientras mas chica la pantalla menos particulas
  party.addSmoke(
    randomInteger(1, innerWidth),
    innerHeight,
    Math.random() * innerWidth * 0.0007
  );
});
*/
//DEFINED
let width_pos = 1;
party.setPreDrawCallback(function () {
  width_pos += Math.floor(innerWidth * 0.1);
  if (width_pos >= innerWidth) width_pos = 1;
  //party.stop(); // stop animating

  //Mientras mas chica la pantalla menos particulas
  party.addSmoke(width_pos, innerHeight, Math.random() * innerWidth * 0.0007);
});

// party.addsmoke(innerWidth/2, innerHeight, 100)
// onclick=e => {
// 	console.log(e)
// 	party.step()
// }

/*
	onmousemove = function (e) {
		var x = e.clientX
		var y = e.clientY
		var n = .5
		var t = Math.floor(Math.random() * 200) + 3800
		party.addsmoke(x, y, n, t)
	}
  */
