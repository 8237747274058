export class CanvasMaskOverride {
    constructor() {
        this.color = true;
        this.opacity = false;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.color !== undefined) {
            this.color = data.color;
        }
        if (data.opacity !== undefined) {
            this.opacity = data.opacity;
        }
    }
}
