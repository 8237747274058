import { setRangeValue, } from "@tsparticles/engine";
import { GradientColorOpacityAnimation } from "./GradientColorOpacityAnimation.js";
export class GradientColorOpacity {
    constructor() {
        this.value = 0;
        this.animation = new GradientColorOpacityAnimation();
    }
    load(data) {
        if (!data) {
            return;
        }
        this.animation.load(data.animation);
        if (data.value !== undefined) {
            this.value = setRangeValue(data.value);
        }
    }
}
