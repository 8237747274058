import { EmitterShapeBase } from "@tsparticles/plugin-emitters";
import { degToRad } from "@tsparticles/engine";
import { generateRandomPointOnPolygonPerimeter, generateRandomPointWithinPolygon, generateRandomPolygon, } from "./utils.js";
const half = 0.5;
export class EmittersPolygonShape extends EmitterShapeBase {
    constructor(position, size, fill, options) {
        super(position, size, fill, options);
        this.sides = options.sides;
        this.angle = degToRad(options.angle);
        this.polygon = generateRandomPolygon(position, this.sides, size.width * half, this.angle);
    }
    async init() {
    }
    randomPosition() {
        const fill = this.fill, polygon = this.polygon, res = fill ? generateRandomPointWithinPolygon(polygon) : generateRandomPointOnPolygonPerimeter(polygon);
        return res ? { position: res } : null;
    }
    resize(position, size) {
        super.resize(position, size);
        this.polygon = generateRandomPolygon(position, this.sides, size.width * half, this.angle);
    }
}
