export class TextLinesOptions {
    constructor() {
        this.separator = "\n";
        this.spacing = 0;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.separator !== undefined) {
            this.separator = data.separator;
        }
        if (data.spacing !== undefined) {
            this.spacing = data.spacing;
        }
    }
}
