const double = 2, doublePI = Math.PI * double, half = 0.5, defaultRotation = 0;
export function polygon(sides, radius, rot = defaultRotation) {
    const step = doublePI / sides, path = [];
    for (let i = 0; i < sides; i++) {
        path.push({ x: Math.cos(i * step + rot) * radius, y: Math.sin(i * step + rot) * radius });
    }
    return path;
}
export function roundedPath(context, path, radius) {
    const index1 = 0, index2 = 1, increment = 1;
    let p1 = path[index1], p2 = path[index2];
    const len = path.length;
    context.moveTo((p1.x + p2.x) * half, (p1.y + p2.y) * half);
    for (let i = 1; i <= len; i++) {
        p1 = p2;
        p2 = path[(i + increment) % len];
        context.arcTo(p1.x, p1.y, (p1.x + p2.x) * half, (p1.y + p2.y) * half, radius);
    }
}
