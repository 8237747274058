const defaultPosition = { x: 50, y: 50 };
export class EmittersPathShapeOptions {
    constructor() {
        this.points = [];
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.points !== undefined) {
            this.points = data.points.map(t => ({ x: t.x ?? defaultPosition.x, y: t.y ?? defaultPosition.y }));
        }
    }
}
