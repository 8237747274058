import { CanvasMask } from "./Options/Classes/CanvasMask.js";
import { CanvasMaskInstance } from "./CanvasMaskInstance.js";
export class CanvasMaskPlugin {
    constructor() {
        this.id = "canvasMask";
    }
    getPlugin(container) {
        return Promise.resolve(new CanvasMaskInstance(container));
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        let canvasMaskOptions = options.canvasMask;
        if (canvasMaskOptions?.load === undefined) {
            options.canvasMask = canvasMaskOptions = new CanvasMask();
        }
        canvasMaskOptions.load(source?.canvasMask);
    }
    needsPlugin(options) {
        return options?.canvasMask?.enable ?? false;
    }
}
