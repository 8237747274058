export class ExportImageInstance {
    constructor(container, engine) {
        this._exportImage = async (data) => {
            const element = this._container.canvas.element;
            if (!element) {
                return;
            }
            return new Promise(resolve => {
                element.toBlob(blob => {
                    if (!blob) {
                        resolve(undefined);
                        return;
                    }
                    resolve(blob);
                }, data.type ?? "image/png", data.quality);
            });
        };
        this._container = container;
        this._engine = engine;
    }
    async export(type, data) {
        const res = {
            supported: false,
        };
        switch (type) {
            case "image":
                res.supported = true;
                res.blob = await this._exportImage(data);
                break;
        }
        return res;
    }
}
