import { getRangeValue, getStyleFromHsl, parseAlpha, percentDenominator, } from "@tsparticles/engine";
const rgbFactor = 255, double = 2, half = 0.5;
export function rgbToHsv(rgb) {
    const rgbPercent = {
        r: rgb.r / rgbFactor,
        g: rgb.g / rgbFactor,
        b: rgb.b / rgbFactor,
    }, xMax = Math.max(rgbPercent.r, rgbPercent.g, rgbPercent.b), xMin = Math.min(rgbPercent.r, rgbPercent.g, rgbPercent.b), v = xMax, c = xMax - xMin;
    let h = 0;
    const phaseOffset = {
        r: 0,
        g: 2,
        b: 4,
    }, phaseValue = 60;
    if (v === rgbPercent.r) {
        h = phaseValue * (phaseOffset.r + (rgbPercent.g - rgbPercent.b) / c);
    }
    else if (v === rgbPercent.g) {
        h = phaseValue * (phaseOffset.g + (rgbPercent.b - rgbPercent.r) / c);
    }
    else if (v === rgbPercent.b) {
        h = phaseValue * (phaseOffset.b + (rgbPercent.r - rgbPercent.g) / c);
    }
    const defaultSaturation = 0, s = !v ? defaultSaturation : c / v;
    return {
        h,
        s: s * percentDenominator,
        v: v * percentDenominator,
    };
}
export function rgbaToHsva(rgba) {
    return {
        a: rgba.a,
        ...rgbToHsv(rgba),
    };
}
export function getStyleFromHsv(color, opacity) {
    return getStyleFromHsl(hsvToHsl(color), opacity);
}
export function hslToHsv(hsl) {
    const l = hsl.l / percentDenominator, sl = hsl.s / percentDenominator, offset = 1, noValue = 0, v = l + sl * Math.min(l, offset - l), sv = !v ? noValue : double * (offset - l / v);
    return {
        h: hsl.h,
        s: sv * percentDenominator,
        v: v * percentDenominator,
    };
}
export function hslaToHsva(hsla) {
    return {
        a: hsla.a,
        ...hslToHsv(hsla),
    };
}
export function hsvToHsl(hsv) {
    const v = hsv.v / percentDenominator, sv = hsv.s / percentDenominator, offset = 1, noValue = 0, l = v * (offset - sv * half), sl = !l || l === offset ? noValue : (v - l) / Math.min(l, offset - l);
    return {
        h: hsv.h,
        l: l * percentDenominator,
        s: sl * percentDenominator,
    };
}
export function hsvaToHsla(hsva) {
    return {
        a: hsva.a,
        ...hsvToHsl(hsva),
    };
}
export function hsvToRgb(hsv) {
    const result = { b: 0, g: 0, r: 0 }, phase = 60, hsvPercent = {
        h: hsv.h / phase,
        s: hsv.s / percentDenominator,
        v: hsv.v / percentDenominator,
    }, offset = 1, hPercentFactor = 2, c = hsvPercent.v * hsvPercent.s, x = c * (offset - Math.abs((hsvPercent.h % hPercentFactor) - offset));
    let tempRgb;
    const cxzRange = { min: 0, max: 1 }, xczRange = { min: 1, max: 2 }, zcxRange = { min: 2, max: 3 }, zxcRange = { min: 3, max: 4 }, xzcRange = { min: 4, max: 5 }, czxRange = { min: 5, max: 6 };
    if (hsvPercent.h >= cxzRange.min && hsvPercent.h <= cxzRange.max) {
        tempRgb = {
            r: c,
            g: x,
            b: 0,
        };
    }
    else if (hsvPercent.h > xczRange.min && hsvPercent.h <= xczRange.max) {
        tempRgb = {
            r: x,
            g: c,
            b: 0,
        };
    }
    else if (hsvPercent.h > zcxRange.min && hsvPercent.h <= zcxRange.max) {
        tempRgb = {
            r: 0,
            g: c,
            b: x,
        };
    }
    else if (hsvPercent.h > zxcRange.min && hsvPercent.h <= zxcRange.max) {
        tempRgb = {
            r: 0,
            g: x,
            b: c,
        };
    }
    else if (hsvPercent.h > xzcRange.min && hsvPercent.h <= xzcRange.max) {
        tempRgb = {
            r: x,
            g: 0,
            b: c,
        };
    }
    else if (hsvPercent.h > czxRange.min && hsvPercent.h <= czxRange.max) {
        tempRgb = {
            r: c,
            g: 0,
            b: x,
        };
    }
    if (tempRgb) {
        const m = hsvPercent.v - c;
        result.r = Math.floor((tempRgb.r + m) * rgbFactor);
        result.g = Math.floor((tempRgb.g + m) * rgbFactor);
        result.b = Math.floor((tempRgb.b + m) * rgbFactor);
    }
    return result;
}
export function hsvaToRgba(hsva) {
    return {
        a: hsva.a,
        ...hsvToRgb(hsva),
    };
}
export class HsvColorManager {
    constructor() {
        this.key = "hsv";
        this.stringPrefix = "hsv";
    }
    handleColor(color) {
        const colorValue = color.value, hsvColor = colorValue.hsv ?? color.value;
        if (hsvColor.h !== undefined && hsvColor.v !== undefined) {
            return hsvToRgb(hsvColor);
        }
    }
    handleRangeColor(color) {
        const colorValue = color.value, hsvColor = colorValue.hsv ?? color.value;
        if (hsvColor.h !== undefined && hsvColor.v !== undefined) {
            return hsvToRgb({
                h: getRangeValue(hsvColor.h),
                s: getRangeValue(hsvColor.s),
                v: getRangeValue(hsvColor.v),
            });
        }
    }
    parseString(input) {
        if (!input.startsWith("hsv")) {
            return;
        }
        const regex = /hsva?\(\s*(\d+)°\s*,\s*(\d+)%\s*,\s*(\d+)%\s*(,\s*([\d.%]+)\s*)?\)/i, result = regex.exec(input), fullLength = 4, indexes = {
            h: 1,
            s: 2,
            v: 3,
            a: 5,
        }, defaultAlpha = 1, radix = 10;
        return result
            ? hsvaToRgba({
                a: result.length > fullLength ? parseAlpha(result[indexes.a]) : defaultAlpha,
                h: parseInt(result[indexes.h], radix),
                s: parseInt(result[indexes.s], radix),
                v: parseInt(result[indexes.v], radix),
            })
            : undefined;
    }
}
