import { degToRad, getStyleFromHsl } from "@tsparticles/engine";
const minWidth = 0, half = 0.5, double = 2;
export function drawEllipse(context, particle, fillColorValue, radius, opacity, width, rotation, start, end) {
    if (width <= minWidth) {
        return;
    }
    const pos = particle.getPosition();
    if (fillColorValue) {
        context.strokeStyle = getStyleFromHsl(fillColorValue, opacity);
    }
    context.lineWidth = width;
    const rotationRadian = degToRad(rotation);
    context.beginPath();
    context.ellipse(pos.x, pos.y, radius * half, radius * double, rotationRadian, start, end);
    context.stroke();
}
