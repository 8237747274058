import { deepExtend } from "@tsparticles/engine";
import { drawPath } from "./Utils.js";
export class PathDrawer {
    constructor() {
        this.validTypes = ["path"];
    }
    draw(data) {
        const { context, particle, radius } = data;
        if (!particle.pathData) {
            return;
        }
        drawPath(context, radius, particle.pathData);
    }
    particleInit(container, particle) {
        const shape = particle.shapeData;
        if (!shape) {
            return;
        }
        particle.pathData = deepExtend({}, shape);
    }
}
