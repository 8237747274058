import { getRangeValue } from "@tsparticles/engine";
import { drawArrow } from "./Utils.js";
const defaultHeightFactor = 0.5, defaultHeadWidthFactor = 0.2, defaultBodyHeightFactor = 0.5;
export class ArrowDrawer {
    constructor() {
        this.validTypes = ["arrow"];
    }
    draw(data) {
        drawArrow(data);
    }
    particleInit(container, particle) {
        const shapeData = particle.shapeData;
        particle.heightFactor = getRangeValue(shapeData?.heightFactor ?? defaultHeightFactor);
        particle.headWidthFactor = getRangeValue(shapeData?.headWidthFactor ?? defaultHeadWidthFactor);
        particle.bodyHeightFactor = getRangeValue(shapeData?.bodyHeightFactor ?? defaultBodyHeightFactor);
    }
}
