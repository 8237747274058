import { ClubDrawer } from "./ClubDrawer.js";
import { DiamondDrawer } from "./DiamondDrawer.js";
import { HeartDrawer } from "./HeartDrawer.js";
import { SpadeDrawer } from "./SpadeDrawer.js";
export async function loadCardsShape(engine, refresh = true) {
    await engine.addShape(new SpadeDrawer(), refresh);
    await engine.addShape(new HeartDrawer(), refresh);
    await engine.addShape(new DiamondDrawer(), refresh);
    await engine.addShape(new ClubDrawer(), refresh);
}
