import { getDistance, getRandom } from "@tsparticles/engine";
const double = 2, doublePI = Math.PI * double;
export class PoissonDisc {
    constructor(size, radius, retries, dimensions, firstPoint) {
        this.size = { ...size };
        this.radius = radius;
        this.retries = retries;
        this.dimensions = dimensions;
        this.cellSize = Math.floor(this.radius / Math.sqrt(this.dimensions));
        this.cols = Math.floor(this.size.width / this.cellSize);
        this.rows = Math.floor(this.size.height / this.cellSize);
        this.points = [];
        this.active = [];
        this.grid = [];
        this.firstPoint = firstPoint ? { ...firstPoint } : undefined;
        this.reset();
    }
    addPoint(inputPoint) {
        const point = {
            position: { ...inputPoint },
            gridPosition: {
                x: Math.floor(inputPoint.x / this.cellSize),
                y: Math.floor(inputPoint.y / this.cellSize),
            },
        }, pointIndex = this.points.length;
        this.points.push(point);
        this.grid[point.gridPosition.y][point.gridPosition.x] = pointIndex;
        this.active.push(pointIndex);
    }
    getRandom(min, max) {
        return Math.floor(getRandom() * (max - min)) + min;
    }
    initialiseGrid() {
        for (let y = 0; y <= this.rows; y++) {
            this.grid[y] = [];
            for (let x = 0; x <= this.cols; x++) {
                this.grid[y][x] = -1;
            }
        }
    }
    reset() {
        this.points = [];
        this.active = [];
        this.grid = [];
        this.initialiseGrid();
        if (this.firstPoint) {
            this.addPoint(this.firstPoint);
        }
        else {
            const minCoordinate = 0;
            this.addPoint({
                x: this.getRandom(minCoordinate, this.size.width),
                y: this.getRandom(minCoordinate, this.size.height),
            });
        }
    }
    async run() {
        this.reset();
        const minCount = 0, step = 1;
        while (this.active.length > minCount) {
            await this.steps(step);
        }
    }
    async steps(steps) {
        const minCount = 0;
        for (let i = 0; i < steps; i++) {
            if (this.active.length <= minCount) {
                continue;
            }
            await this._step();
        }
    }
    _getNewPoint(currentPoint, tries) {
        const minCoordinate = 0, gridMinValue = 0, maxNeighbourIndex = 1, newAngle = tries * (doublePI / this.retries), newDist = this.getRandom(this.radius, this.radius * double), offset = {
            x: Math.cos(newAngle) * newDist,
            y: Math.sin(newAngle) * newDist,
        }, newPoint = {
            x: Math.floor(currentPoint.position.x + offset.x),
            y: Math.floor(currentPoint.position.y + offset.y),
        }, newGridCoords = {
            x: Math.floor(newPoint.x / this.cellSize),
            y: Math.floor(newPoint.y / this.cellSize),
        };
        if (newPoint.x > minCoordinate &&
            newPoint.x < this.size.width &&
            newPoint.y > minCoordinate &&
            newPoint.y < this.size.height) {
            if (this.grid[newGridCoords.y][newGridCoords.x] < gridMinValue) {
                for (let i = -1; i <= maxNeighbourIndex; i++) {
                    for (let j = -1; j <= maxNeighbourIndex; j++) {
                        const neighbourGrid = {
                            x: newGridCoords.x + j,
                            y: newGridCoords.y + i,
                        };
                        if (neighbourGrid.x >= minCoordinate &&
                            neighbourGrid.y >= minCoordinate &&
                            neighbourGrid.x < this.cols &&
                            neighbourGrid.y < this.rows &&
                            (neighbourGrid.x !== newGridCoords.x || neighbourGrid.y !== newGridCoords.y)) {
                            if (this.grid[neighbourGrid.y][neighbourGrid.x] >= gridMinValue) {
                                const neighbourIndex = this.grid[neighbourGrid.y][neighbourGrid.x], neighbour = this.points[neighbourIndex], dist = getDistance(newPoint, neighbour.position);
                                if (dist < this.radius) {
                                    return;
                                }
                            }
                        }
                    }
                }
            }
            else {
                return;
            }
        }
        else {
            return;
        }
        return newPoint;
    }
    async _step() {
        const minCount = 0, randomActive = this.getRandom(minCount, this.active.length);
        return new Promise(resolve => {
            let foundNewPoint = false;
            for (let tries = 0; tries < this.retries; tries++) {
                const newPoint = this._getNewPoint(this.points[this.active[randomActive]], tries);
                if (newPoint) {
                    foundNewPoint = true;
                    this.addPoint(newPoint);
                    break;
                }
            }
            if (!foundNewPoint) {
                const deleteCount = 1;
                this.active.splice(randomActive, deleteCount);
            }
            resolve();
        });
    }
}
