import { Particles } from "./marcbruederlin/Particles.js";
import { initParticlesJS } from "./VincentGarreau/particles.js";
const initPjs = (engine) => {
    const { particlesJS, pJSDom } = initParticlesJS(engine);
    window.particlesJS = particlesJS;
    window.pJSDom = pJSDom;
    window.Particles = Particles;
    return { particlesJS, pJSDom, Particles };
};
export { initPjs };
