import { PolygonMaskDrawStroke } from "./PolygonMaskDrawStroke.js";
export class PolygonMaskDraw {
    constructor() {
        this.enable = false;
        this.stroke = new PolygonMaskDrawStroke();
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.enable !== undefined) {
            this.enable = data.enable;
        }
        const stroke = data.stroke;
        this.stroke.load(stroke);
    }
}
