import { getRandom } from "@tsparticles/engine";
const maxAttempts = 100, half = 0.5;
export function generateRandomPointWithinPath(ctx, path, center, size) {
    let randomPoint = null;
    for (let attempts = 0; attempts < maxAttempts; attempts++) {
        const tmpPoint = {
            x: center.x + getRandom() * size.width - size.width * half,
            y: center.y + getRandom() * size.height - size.height * half,
        };
        if (ctx.isPointInPath(path, tmpPoint.x, tmpPoint.y)) {
            randomPoint = tmpPoint;
            break;
        }
    }
    return randomPoint;
}
export function generateRandomPointOnPathPerimeter(ctx, path, center, size) {
    let randomPoint = null;
    for (let attempts = 0; attempts < maxAttempts; attempts++) {
        const tmpPoint = {
            x: center.x + getRandom() * size.width - size.width * half,
            y: center.y + getRandom() * size.height - size.height * half,
        };
        if (ctx.isPointInStroke(path, tmpPoint.x, tmpPoint.y)) {
            randomPoint = tmpPoint;
            break;
        }
    }
    return randomPoint;
}
