import { getRangeValue, } from "@tsparticles/engine";
import { polygon, roundedPath } from "./Utils.js";
const defaultSides = 5, defaultRadius = 5;
export class RoundedPolygonDrawer {
    constructor() {
        this.validTypes = ["rounded-polygon"];
    }
    draw(data) {
        const { context, particle, radius } = data;
        roundedPath(context, polygon(particle.sides, radius), particle.borderRadius ?? defaultRadius);
    }
    getSidesCount(particle) {
        const roundedPolygon = particle.shapeData;
        return Math.round(getRangeValue(roundedPolygon?.sides ?? defaultSides));
    }
    particleInit(container, particle) {
        const shapeData = particle.shapeData;
        particle.borderRadius =
            Math.round(getRangeValue(shapeData?.radius ?? defaultSides)) * container.retina.pixelRatio;
    }
}
