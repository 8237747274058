import { EmitterShapeBase } from "@tsparticles/plugin-emitters";
import { errorPrefix, percentDenominator } from "@tsparticles/engine";
import { generateRandomPointOnPathPerimeter, generateRandomPointWithinPath } from "./utils.js";
const half = 0.5;
export class EmittersPathShape extends EmitterShapeBase {
    constructor(position, size, fill, options) {
        super(position, size, fill, options);
        const ctx = document.createElement("canvas").getContext("2d");
        if (!ctx) {
            throw new Error(`${errorPrefix} No 2d context available`);
        }
        this.checkContext = ctx;
        this.points = options.points;
        const pathData = this.points, path = new Path2D(), offset = {
            x: position.x - size.width * half,
            y: position.y - size.height * half,
        };
        for (const [index, point] of pathData.entries()) {
            const coords = {
                x: offset.x + (point.x * size.width) / percentDenominator,
                y: offset.y + (point.y * size.height) / percentDenominator,
            };
            if (!index) {
                path.moveTo(coords.x, coords.y);
            }
            else {
                path.lineTo(coords.x, coords.y);
            }
        }
        const firstIndex = 0, firstPathData = pathData[firstIndex];
        if (firstPathData) {
            const coords = {
                x: offset.x + (firstPathData.x * size.width) / percentDenominator,
                y: offset.y + (firstPathData.y * size.height) / percentDenominator,
            };
            path.lineTo(coords.x, coords.y);
        }
        this.path = path;
    }
    async init() {
    }
    randomPosition() {
        const ctx = this.checkContext, position = this.position, size = this.size, fill = this.fill, path = this.path, res = fill
            ? generateRandomPointWithinPath(ctx, path, position, size)
            : generateRandomPointOnPathPerimeter(ctx, path, position, size);
        return res ? { position: res } : null;
    }
    resize(position, size) {
        super.resize(position, size);
        const pathData = this.points, path = new Path2D(), offset = {
            x: position.x - size.width * half,
            y: position.y - size.height * half,
        };
        for (const [index, point] of pathData.entries()) {
            const coords = {
                x: offset.x + (point.x * size.width) / percentDenominator,
                y: offset.y + (point.y * size.height) / percentDenominator,
            };
            if (!index) {
                path.moveTo(coords.x, coords.y);
            }
            else {
                path.lineTo(coords.x, coords.y);
            }
        }
        const firstIndex = 0, firstPathData = pathData[firstIndex];
        if (firstPathData) {
            const coords = {
                x: offset.x + (firstPathData.x * size.width) / percentDenominator,
                y: offset.y + (firstPathData.y * size.height) / percentDenominator,
            };
            path.lineTo(coords.x, coords.y);
        }
        this.path = path;
    }
}
