import { deepExtend } from "@tsparticles/engine";
export class InteractivityParticle {
    constructor() {
        this.replaceCursor = false;
        this.pauseOnStop = false;
        this.stopDelay = 0;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.options !== undefined) {
            this.options = deepExtend({}, data.options);
        }
        if (data.replaceCursor !== undefined) {
            this.replaceCursor = data.replaceCursor;
        }
        if (data.pauseOnStop !== undefined) {
            this.pauseOnStop = data.pauseOnStop;
        }
        if (data.stopDelay !== undefined) {
            this.stopDelay = data.stopDelay;
        }
    }
}
