export class PixelsOptions {
    constructor() {
        this.offset = 4;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.offset !== undefined) {
            this.offset = data.offset;
        }
    }
}
