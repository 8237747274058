import { addParticlesFromCanvasPixels, getCanvasImageData, getImageData, getTextData, } from "./utils.js";
export class CanvasMaskInstance {
    constructor(container) {
        this._container = container;
    }
    async init() {
        const container = this._container, options = container.actualOptions.canvasMask;
        if (!options?.enable) {
            return;
        }
        let pixelData = {
            pixels: [],
            height: 0,
            width: 0,
        };
        const offset = options.pixels.offset;
        if (options.image) {
            const url = options.image.src;
            if (!url) {
                return;
            }
            pixelData = await getImageData(url, offset);
        }
        else if (options.text) {
            const textOptions = options.text;
            const data = getTextData(textOptions, offset);
            if (!data) {
                return;
            }
            pixelData = data;
        }
        else if (options.element ?? options.selector) {
            const canvas = options.element ?? (options.selector && document.querySelector(options.selector));
            if (!canvas) {
                return;
            }
            const context = canvas.getContext("2d");
            if (!context) {
                return;
            }
            pixelData = getCanvasImageData(context, canvas, offset);
        }
        addParticlesFromCanvasPixels(container, pixelData, options.position, options.scale, options.override, options.pixels.filter);
    }
}
