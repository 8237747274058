import { getRangeValue } from "@tsparticles/engine";
import { drawSpiral } from "./Utils.js";
const defaultInnerRadius = 1, defaultLineSpacing = 1, defaultWidthFactor = 10;
export class SpiralDrawer {
    constructor() {
        this.validTypes = ["spiral"];
    }
    draw(data) {
        drawSpiral(data);
    }
    particleInit(container, particle) {
        const pixelRatio = container.retina.pixelRatio, shapeData = particle.shapeData;
        particle.spiralInnerRadius = getRangeValue(shapeData?.innerRadius ?? defaultInnerRadius) * pixelRatio;
        particle.spiralLineSpacing = getRangeValue(shapeData?.lineSpacing ?? defaultLineSpacing) * pixelRatio;
        particle.spiralWidthFactor = getRangeValue(shapeData?.widthFactor ?? defaultWidthFactor);
    }
}
