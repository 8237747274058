const indent = 2;
export class ExportJSONInstance {
    constructor(container, engine) {
        this._exportJSON = async () => {
            const json = JSON.stringify(this._container.actualOptions, (key, value) => {
                if (key.startsWith("_")) {
                    return;
                }
                return value;
            }, indent);
            return Promise.resolve(new Blob([json], { type: "application/json" }));
        };
        this._container = container;
        this._engine = engine;
    }
    async export(type) {
        const res = {
            supported: false,
        };
        switch (type) {
            case "json":
                res.supported = true;
                res.blob = await this._exportJSON();
                break;
        }
        return res;
    }
}
