import { OutMode, OutModeDirection, } from "@tsparticles/engine";
import { BounceOutMode } from "./BounceOutMode.js";
import { DestroyOutMode } from "./DestroyOutMode.js";
import { NoneOutMode } from "./NoneOutMode.js";
import { OutOutMode } from "./OutOutMode.js";
const checkOutMode = (outModes, outMode) => {
    return (outModes.default === outMode ||
        outModes.bottom === outMode ||
        outModes.left === outMode ||
        outModes.right === outMode ||
        outModes.top === outMode);
};
export class OutOfCanvasUpdater {
    constructor(container) {
        this._updateOutMode = (particle, delta, outMode, direction) => {
            for (const updater of this.updaters) {
                updater.update(particle, direction, delta, outMode);
            }
        };
        this.container = container;
        this.updaters = [];
    }
    init(particle) {
        this.updaters = [];
        const outModes = particle.options.move.outModes;
        if (checkOutMode(outModes, OutMode.bounce)) {
            this.updaters.push(new BounceOutMode(this.container));
        }
        else if (checkOutMode(outModes, OutMode.out)) {
            this.updaters.push(new OutOutMode(this.container));
        }
        else if (checkOutMode(outModes, OutMode.destroy)) {
            this.updaters.push(new DestroyOutMode(this.container));
        }
        else if (checkOutMode(outModes, OutMode.none)) {
            this.updaters.push(new NoneOutMode(this.container));
        }
    }
    isEnabled(particle) {
        return !particle.destroyed && !particle.spawning;
    }
    update(particle, delta) {
        const outModes = particle.options.move.outModes;
        this._updateOutMode(particle, delta, outModes.bottom ?? outModes.default, OutModeDirection.bottom);
        this._updateOutMode(particle, delta, outModes.left ?? outModes.default, OutModeDirection.left);
        this._updateOutMode(particle, delta, outModes.right ?? outModes.default, OutModeDirection.right);
        this._updateOutMode(particle, delta, outModes.top ?? outModes.default, OutModeDirection.top);
    }
}
