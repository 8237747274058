import { ValueWithRandom } from "@tsparticles/engine";
export class OrbitRotation extends ValueWithRandom {
    constructor() {
        super();
        this.value = 45;
    }
    load(data) {
        if (data === undefined) {
            return;
        }
        super.load(data);
    }
}
