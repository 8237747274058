import { errorPrefix, isNumber } from "@tsparticles/engine";
const origin = {
    x: 0,
    y: 0,
}, minWidth = 0;
export function getCanvasImageData(ctx, size, offset, clear = true) {
    const imageData = ctx.getImageData(origin.x, origin.y, size.width, size.height).data;
    if (clear) {
        ctx.clearRect(origin.x, origin.y, size.width, size.height);
    }
    const pixels = [];
    for (let i = 0; i < imageData.length; i += offset) {
        const idx = i / offset, pos = {
            x: idx % size.width,
            y: Math.floor(idx / size.width),
        };
        if (!pixels[pos.y]) {
            pixels[pos.y] = [];
        }
        const indexesOffset = {
            r: 0,
            g: 1,
            b: 2,
            a: 3,
        }, alphaFactor = 255;
        pixels[pos.y][pos.x] = {
            r: imageData[i + indexesOffset.r],
            g: imageData[i + indexesOffset.g],
            b: imageData[i + indexesOffset.b],
            a: imageData[i + indexesOffset.a] / alphaFactor,
        };
    }
    return {
        pixels,
        width: Math.min(...pixels.map(row => row.length)),
        height: pixels.length,
    };
}
export function getImageData(src, offset) {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    const p = new Promise((resolve, reject) => {
        image.onerror = reject;
        image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext("2d");
            if (!context) {
                return reject(new Error(`${errorPrefix} Could not get canvas context`));
            }
            context.drawImage(image, origin.x, origin.y, image.width, image.height, origin.x, origin.y, canvas.width, canvas.height);
            resolve(getCanvasImageData(context, canvas, offset));
        };
    });
    image.src = src;
    return p;
}
export function getTextData(textOptions, offset, fill) {
    const canvas = document.createElement("canvas"), context = canvas.getContext("2d"), { font, text, lines: linesOptions, color } = textOptions;
    if (!text || !context) {
        return;
    }
    const lines = text.split(linesOptions.separator), fontSize = isNumber(font.size) ? `${font.size}px` : font.size, linesData = [];
    let maxWidth = 0, totalHeight = 0;
    for (const line of lines) {
        context.font = `${font.style || ""} ${font.variant || ""} ${font.weight || ""} ${fontSize} ${font.family}`;
        const measure = context.measureText(line), lineData = {
            measure,
            text: line,
            height: measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent,
            width: measure.width,
        };
        maxWidth = Math.max(maxWidth || minWidth, lineData.width);
        totalHeight += lineData.height + linesOptions.spacing;
        linesData.push(lineData);
    }
    canvas.width = maxWidth;
    canvas.height = totalHeight;
    let currentHeight = 0;
    for (const line of linesData) {
        context.font = `${font.style || ""} ${font.variant || ""} ${font.weight || ""} ${fontSize} ${font.family}`;
        if (fill) {
            context.fillStyle = color;
            context.fillText(line.text, origin.x, currentHeight + line.measure.actualBoundingBoxAscent);
        }
        else {
            context.strokeStyle = color;
            context.strokeText(line.text, origin.x, currentHeight + line.measure.actualBoundingBoxAscent);
        }
        currentHeight += line.height + linesOptions.spacing;
    }
    return getCanvasImageData(context, canvas, offset);
}
