import { PolygonMaskInlineArrangement, } from "../../Enums/PolygonMaskInlineArrangement.js";
export class PolygonMaskInline {
    constructor() {
        this.arrangement = PolygonMaskInlineArrangement.onePerPoint;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.arrangement !== undefined) {
            this.arrangement = data.arrangement;
        }
    }
}
