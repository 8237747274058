import { Infection } from "./Options/Classes/Infection.js";
import { InfectionInstance } from "./InfectionInstance.js";
export class InfectionPlugin {
    constructor() {
        this.id = "infection";
    }
    getPlugin(container) {
        return Promise.resolve(new InfectionInstance(container));
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        let infectionOptions = options.infection;
        if (infectionOptions?.load === undefined) {
            options.infection = infectionOptions = new Infection();
        }
        infectionOptions.load(source?.infection);
    }
    needsPlugin(options) {
        return options?.infection?.enable ?? false;
    }
}
