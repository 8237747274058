import { RotateDirection, Vector, calcPositionOrRandomFromSize, calcPositionOrRandomFromSizeRanged, getDistance, getDistances, getRandom, getRangeValue, getStyleFromRgb, isPointInside, percentDenominator, rangeColorToRgb, } from "@tsparticles/engine";
import { Absorber } from "./Options/Classes/Absorber.js";
const squareExp = 2, absorbFactor = 0.033, minOrbitLength = 0, minRadius = 0, minMass = 0, origin = {
    x: 0,
    y: 0,
}, minAngle = 0, double = 2, maxAngle = Math.PI * double, minVelocity = 0;
export class AbsorberInstance {
    constructor(absorbers, container, options, position) {
        this.absorbers = absorbers;
        this.container = container;
        this._calcPosition = () => {
            const exactPosition = calcPositionOrRandomFromSizeRanged({
                size: this.container.canvas.size,
                position: this.options.position,
            });
            return Vector.create(exactPosition.x, exactPosition.y);
        };
        this._updateParticlePosition = (particle, v) => {
            if (particle.destroyed) {
                return;
            }
            const container = this.container, canvasSize = container.canvas.size;
            if (particle.needsNewPosition) {
                const newPosition = calcPositionOrRandomFromSize({ size: canvasSize });
                particle.position.setTo(newPosition);
                particle.velocity.setTo(particle.initialVelocity);
                particle.absorberOrbit = undefined;
                particle.needsNewPosition = false;
            }
            if (this.options.orbits) {
                if (particle.absorberOrbit === undefined) {
                    particle.absorberOrbit = Vector.origin;
                    particle.absorberOrbit.length = getDistance(particle.getPosition(), this.position);
                    particle.absorberOrbit.angle = getRandom() * maxAngle;
                }
                if (particle.absorberOrbit.length <= this.size && !this.options.destroy) {
                    const minSize = Math.min(canvasSize.width, canvasSize.height), offset = 1, randomOffset = 0.1, randomFactor = 0.2;
                    particle.absorberOrbit.length = minSize * (offset + (getRandom() * randomFactor - randomOffset));
                }
                if (particle.absorberOrbitDirection === undefined) {
                    particle.absorberOrbitDirection =
                        particle.velocity.x >= minVelocity ? RotateDirection.clockwise : RotateDirection.counterClockwise;
                }
                const orbitRadius = particle.absorberOrbit.length, orbitAngle = particle.absorberOrbit.angle, orbitDirection = particle.absorberOrbitDirection;
                particle.velocity.setTo(Vector.origin);
                const updateFunc = {
                    x: orbitDirection === RotateDirection.clockwise ? Math.cos : Math.sin,
                    y: orbitDirection === RotateDirection.clockwise ? Math.sin : Math.cos,
                };
                particle.position.x = this.position.x + orbitRadius * updateFunc.x(orbitAngle);
                particle.position.y = this.position.y + orbitRadius * updateFunc.y(orbitAngle);
                particle.absorberOrbit.length -= v.length;
                particle.absorberOrbit.angle +=
                    (((particle.retina.moveSpeed ?? minVelocity) * container.retina.pixelRatio) / percentDenominator) *
                        container.retina.reduceFactor;
            }
            else {
                const addV = Vector.origin;
                addV.length = v.length;
                addV.angle = v.angle;
                particle.velocity.addTo(addV);
            }
        };
        this.initialPosition = position ? Vector.create(position.x, position.y) : undefined;
        if (options instanceof Absorber) {
            this.options = options;
        }
        else {
            this.options = new Absorber();
            this.options.load(options);
        }
        this.dragging = false;
        this.name = this.options.name;
        this.opacity = this.options.opacity;
        this.size = getRangeValue(this.options.size.value) * container.retina.pixelRatio;
        this.mass = this.size * this.options.size.density * container.retina.reduceFactor;
        const limit = this.options.size.limit;
        this.limit = {
            radius: limit.radius * container.retina.pixelRatio * container.retina.reduceFactor,
            mass: limit.mass,
        };
        this.color = rangeColorToRgb(this.options.color) ?? {
            b: 0,
            g: 0,
            r: 0,
        };
        this.position = this.initialPosition?.copy() ?? this._calcPosition();
    }
    attract(particle) {
        const container = this.container, options = this.options;
        if (options.draggable) {
            const mouse = container.interactivity.mouse;
            if (mouse.clicking && mouse.downPosition) {
                const mouseDist = getDistance(this.position, mouse.downPosition);
                if (mouseDist <= this.size) {
                    this.dragging = true;
                }
            }
            else {
                this.dragging = false;
            }
            if (this.dragging && mouse.position) {
                this.position.x = mouse.position.x;
                this.position.y = mouse.position.y;
            }
        }
        const pos = particle.getPosition(), { dx, dy, distance } = getDistances(this.position, pos), v = Vector.create(dx, dy);
        v.length = (this.mass / Math.pow(distance, squareExp)) * container.retina.reduceFactor;
        if (distance < this.size + particle.getRadius()) {
            const sizeFactor = particle.getRadius() * absorbFactor * container.retina.pixelRatio;
            if ((this.size > particle.getRadius() && distance < this.size - particle.getRadius()) ||
                (particle.absorberOrbit !== undefined && particle.absorberOrbit.length < minOrbitLength)) {
                if (options.destroy) {
                    particle.destroy();
                }
                else {
                    particle.needsNewPosition = true;
                    this._updateParticlePosition(particle, v);
                }
            }
            else {
                if (options.destroy) {
                    particle.size.value -= sizeFactor;
                }
                this._updateParticlePosition(particle, v);
            }
            if (this.limit.radius <= minRadius || this.size < this.limit.radius) {
                this.size += sizeFactor;
            }
            if (this.limit.mass <= minMass || this.mass < this.limit.mass) {
                this.mass += sizeFactor * this.options.size.density * container.retina.reduceFactor;
            }
        }
        else {
            this._updateParticlePosition(particle, v);
        }
    }
    draw(context) {
        context.translate(this.position.x, this.position.y);
        context.beginPath();
        context.arc(origin.x, origin.y, this.size, minAngle, maxAngle, false);
        context.closePath();
        context.fillStyle = getStyleFromRgb(this.color, this.opacity);
        context.fill();
    }
    resize() {
        const initialPosition = this.initialPosition;
        this.position =
            initialPosition && isPointInside(initialPosition, this.container.canvas.size, Vector.origin)
                ? initialPosition
                : this._calcPosition();
    }
}
