import { Vector, getRandom, getRangeValue, } from "@tsparticles/engine";
const angularFrequencyFactor = 0.5, half = 0.5, halfPI = Math.PI * half;
export class ZigZagPathGenerator {
    constructor() {
        this.options = {
            waveHeight: { min: 0, max: 3 },
            waveLength: { min: 0, max: 5 },
        };
    }
    generate(particle, delta) {
        if (particle.zigzag === undefined) {
            particle.zigzag = {
                counter: getRandom(),
                waveHeight: getRangeValue(this.options.waveHeight),
                waveLength: getRangeValue(this.options.waveLength),
            };
        }
        const angularFrequency = (angularFrequencyFactor / particle.zigzag.waveLength) * delta.factor;
        particle.zigzag.counter += angularFrequency;
        const zigzagAngle = particle.zigzag.waveHeight * Math.sin(particle.zigzag.counter);
        particle.position.x += zigzagAngle * Math.cos(particle.velocity.angle + halfPI);
        particle.position.y += zigzagAngle * Math.sin(particle.velocity.angle + halfPI);
        return Vector.origin;
    }
    init(container) {
        const options = container.actualOptions.particles.move.path.options;
        this.options.waveLength = options.waveLength ?? this.options.waveLength;
        this.options.waveHeight = options.waveHeight ?? this.options.waveHeight;
    }
    reset() {
    }
    update() {
    }
}
