import { PolygonMask } from "./Options/Classes/PolygonMask.js";
import { PolygonMaskInstance } from "./PolygonMaskInstance.js";
import { PolygonMaskType } from "./Enums/PolygonMaskType.js";
export class PolygonMaskPlugin {
    constructor(engine) {
        this.id = "polygonMask";
        this._engine = engine;
    }
    getPlugin(container) {
        return Promise.resolve(new PolygonMaskInstance(container, this._engine));
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        let polygonOptions = options.polygon;
        if (polygonOptions?.load === undefined) {
            options.polygon = polygonOptions = new PolygonMask();
        }
        polygonOptions.load(source?.polygon);
    }
    needsPlugin(options) {
        return (options?.polygon?.enable ??
            (options?.polygon?.type !== undefined && options.polygon.type !== PolygonMaskType.none));
    }
}
