import { isObject } from "@tsparticles/engine";
export class SoundsAudio {
    constructor() {
        this.loop = false;
        this.source = "";
    }
    load(data) {
        if (data === undefined) {
            return;
        }
        if (isObject(data)) {
            if (data.loop !== undefined) {
                this.loop = data.loop;
            }
            if (data.source !== undefined) {
                this.source = data.source;
            }
        }
        else {
            this.source = data;
        }
    }
}
