export class FontTextMask {
    constructor() {
        this.family = "sans-serif";
        this.size = 100;
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.family !== undefined) {
            this.family = data.family;
        }
        if (data.size !== undefined) {
            this.size = data.size;
        }
        if (data.style !== undefined) {
            this.style = data.style;
        }
        if (data.variant !== undefined) {
            this.variant = data.variant;
        }
        if (data.weight !== undefined) {
            this.weight = data.weight;
        }
    }
}
