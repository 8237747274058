import { itemFromArray } from "@tsparticles/engine";
import { Infecter } from "./Infecter.js";
const minStage = 0;
export class InfectionInstance {
    constructor(container) {
        this._container = container;
        this._container.infecter = new Infecter(this._container);
    }
    particleFillColor(particle) {
        const options = this._container.actualOptions;
        if (!particle.infection || !options.infection) {
            return;
        }
        const infectionStage = particle.infection.stage, infection = options.infection, infectionStages = infection.stages;
        return infectionStage !== undefined ? infectionStages[infectionStage].color : undefined;
    }
    particleStrokeColor(particle) {
        return this.particleFillColor(particle);
    }
    particlesSetup() {
        const options = this._container.actualOptions;
        if (!options.infection) {
            return;
        }
        for (let i = 0; i < options.infection.infections; i++) {
            const notInfected = this._container.particles.filter(p => {
                const infP = p;
                if (!infP.infection) {
                    infP.infection = {};
                }
                return infP.infection.stage === undefined;
            });
            const infected = itemFromArray(notInfected);
            this._container.infecter?.startInfection(infected, minStage);
        }
    }
}
