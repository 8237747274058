import { getRandom } from "@tsparticles/engine";
const double = 2, doublePI = Math.PI * double, defaultRotation = 0, maxAttempts = 100;
export function generateRandomPolygon(position, sides, radius, rotationAngle = defaultRotation) {
    const polygon = [], angle = doublePI / sides;
    for (let i = 0; i < sides; i++) {
        const currentAngle = angle * i + rotationAngle;
        polygon.push({
            x: position.x + radius * Math.cos(currentAngle),
            y: position.y + radius * Math.sin(currentAngle),
        });
    }
    return polygon;
}
export function generateRandomPointWithinPolygon(polygon) {
    const firstIndex = 0, firstPoint = polygon[firstIndex], min = { ...firstPoint }, max = { ...firstPoint };
    for (const point of polygon) {
        if (point.x < min.x) {
            min.x = point.x;
        }
        if (point.x > max.x) {
            max.x = point.x;
        }
        if (point.y < min.y) {
            min.y = point.y;
        }
        if (point.y > max.y) {
            max.y = point.y;
        }
    }
    let randomPoint = null;
    for (let attempts = 0; attempts < maxAttempts; attempts++) {
        const tmpPoint = {
            x: min.x + getRandom() * (max.x - min.x),
            y: min.y + getRandom() * (max.y - min.y),
        };
        if (isPointInPolygon(tmpPoint, polygon)) {
            randomPoint = tmpPoint;
            break;
        }
    }
    return randomPoint;
}
export function generateRandomPointOnPolygonPerimeter(polygon) {
    const sideIndex = Math.floor(getRandom() * polygon.length), startPoint = polygon[sideIndex], offset = 1, endPoint = polygon[(sideIndex + offset) % polygon.length], t = getRandom();
    return { x: startPoint.x + (endPoint.x - startPoint.x) * t, y: startPoint.y + (endPoint.y - startPoint.y) * t };
}
export function isPointInPolygon(point, polygon) {
    let inside = false;
    const offset = 1;
    for (let i = 0, j = polygon.length - offset; i < polygon.length; j = i++) {
        const pi = polygon[i], pj = polygon[j];
        const intersect = pi.y > point.y !== pj.y > point.y && point.x < ((pj.x - pi.x) * (point.y - pi.y)) / (pj.y - pi.y) + pi.x;
        if (intersect) {
            inside = !inside;
        }
    }
    return inside;
}
