import { ExportJSONInstance } from "./ExportJSONInstance.js";
export class ExportJSONPlugin {
    constructor(engine) {
        this.id = "export-json";
        this._engine = engine;
    }
    getPlugin(container) {
        return Promise.resolve(new ExportJSONInstance(container, this._engine));
    }
    loadOptions() {
    }
    needsPlugin() {
        return true;
    }
}
