import { RotateDirection, setRangeValue, } from "@tsparticles/engine";
import { GradientAngleAnimation } from "./GradientAngleAnimation.js";
export class GradientAngle {
    constructor() {
        this.value = 0;
        this.animation = new GradientAngleAnimation();
        this.direction = RotateDirection.clockwise;
    }
    load(data) {
        if (!data) {
            return;
        }
        this.animation.load(data.animation);
        if (data.value !== undefined) {
            this.value = setRangeValue(data.value);
        }
        if (data.direction !== undefined) {
            this.direction = data.direction;
        }
    }
}
