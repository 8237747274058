import { PixelsOptions } from "./PixelsOptions.js";
import { TextOptions } from "./TextOptions.js";
const minAlpha = 0;
export class EmittersCanvasShapeOptions {
    constructor() {
        this.filter = (pixel) => pixel.a > minAlpha;
        this.pixels = new PixelsOptions();
        this.scale = 1;
        this.selector = "";
        this.text = new TextOptions();
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.element !== undefined) {
            this.element = data.element;
        }
        if (data.filter !== undefined) {
            this.filter = data.filter;
        }
        this.pixels.load(data.pixels);
        if (data.scale !== undefined) {
            this.scale = data.scale;
        }
        if (data.selector !== undefined) {
            this.selector = data.selector;
        }
        if (data.image !== undefined) {
            this.image = data.image;
        }
        this.text.load(data.text);
    }
}
