import { ExportImageInstance } from "./ExportImageInstance.js";
export class ExportImagePlugin {
    constructor(engine) {
        this.id = "export-image";
        this._engine = engine;
    }
    getPlugin(container) {
        return Promise.resolve(new ExportImageInstance(container, this._engine));
    }
    loadOptions() {
    }
    needsPlugin() {
        return true;
    }
}
